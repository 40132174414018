<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <b-card
      :title="`${$t('Cash Book')} ${$t('To Edit')}`"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>

            <!-- Field: Type -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="type"
                rules="required"
              >
                <b-form-group
                  :state="getValidationState(validationContext)"
                >
                  <b-form-radio-group
                    v-model="cashbookData.type"
                    :options="typeOptions"
                    class="demo-inline-spacing mb-1"
                    value-field="value"
                    text-field="text"
                    :disabled="true"
                    @input="loadLedgers"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Date Flat Picker is used -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="cash-date"
                rules="required"
              >
                <b-form-group
                  :label="$t('Date')"
                  label-for="cash-date"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-prepend>
                    <flat-pickr
                      id="cash-date"
                      v-model="cashbookData.cash_date"
                      class="form-control invoice-edit-input"
                      :config="{ dateFormat: 'Y-m-d', minDate: null, maxDate: null }"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder=""
                    />
                  </b-input-group>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Description -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="description"
                rules="required"
              >
                <b-form-group
                  :label="$t('Description')"
                  label-for="description"
                >
                  <b-form-input
                    id="description"
                    v-model="cashbookData.description"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Amount -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="amount"
                rules="required"
              >
                <b-form-group
                  :label="$t('Amount')"
                  label-for="amount"
                >
                  <b-form-input
                    id="amount"
                    v-model="cashbookData.amount"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Ledger -->
            <b-col
              cols="12"
              md="4"
            >

              <validation-provider
                #default="validationContext"
                name="ledger"
                rules="required"
              >
                <b-form-group
                  :label="$t('Ledger')"
                  label-for="ledger"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="cashbookData.ledger_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="ledgerOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="ledger"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Branch -->
            <b-col
              v-if="$can('audit', 'all')"
              cols="12"
              md="4"
            >
              <!-- Supplier Branch -->
              <validation-provider
                #default="validationContext"
                name="Branch"
                rules="required"
              >
                <b-form-group
                  :label="$t('Branch')"
                  label-for="branch"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="cashbookData.branch_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="branchOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="branch"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-primary"
              class="mr-2"
              pill
              type="submit"
            >
              {{ $t('Save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="gradient-secondary"
              pill
              @click="cancel"
            >
              {{ $t('Close') }}
            </b-button>
          </div>

        </b-form>

      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BInputGroup, BInputGroupPrepend,
  BFormRadioGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required, integer, regex } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from '@/libs/vue-select'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import storeModule from '../../../common/storeModule'
import cashbookStoreModule from '../cashbookStoreModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,
    flatPickr,
    BInputGroup,
    BInputGroupPrepend,
    BFormRadioGroup,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {

  },
  data() {
    return {
      required,
      integer,
      regex,
      ledgerOptions: [],
      branchOptions: [],
      typeOptions: [
        { text: this.$t('Cash In'), value: 'cash_in' },
        { text: this.$t('Cash Out'), value: 'cash_out' },
      ],
    }
  },
  mounted() {
    this.loadBranches()
  },
  methods: {
    loadLedgers(type) {
      store
        .dispatch('common/fetchLedgers', { type })
        .then(response => {
          this.ledgerOptions = []
          const { ledgers } = response.data.data
          for (let i = 0; i < ledgers.length; i += 1) {
            this.ledgerOptions.push({ label: ledgers[i].name, value: ledgers[i].id })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadBranches() {
      store
        .dispatch('common/fetchBranches')
        .then(response => {
          const { branches } = response.data.data
          for (let i = 0; i < branches.length; i += 1) {
            this.branchOptions.push({ label: branches[i].name, value: branches[i].id })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    cancel() {
      this.$router.push({ name: 'cashbook-list' })
    },
  },
  setup(props, context) {
    const STORE_MODULE_NAME = 'cashbook'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, cashbookStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const blankCashbookData = {
      cash_date: '',
      description: '',
      amount: null,
      type: null,
      ledger_id: null,
      branch_id: null,
    }
    const router = context.root.$router
    const cashbookData = ref(JSON.parse(JSON.stringify(blankCashbookData)))
    const cashbookId = router.currentRoute.params.id
    store.dispatch('cashbook/fetchOne', { id: cashbookId })
      .then(response => {
        const responseData = {
          id: response.data.data.id,
          cash_date: response.data.data.cash_date,
          description: response.data.data.description,
          amount: response.data.data.amount,
          type: response.data.data.type,
          ledger_id: response.data.data.ledger_id,
          branch_id: response.data.data.branch_id,
        }
        cashbookData.value = responseData
      })
      .catch(error => {
        if (error.response.status === 404) {
          cashbookData.value = undefined
        }
      })

    const onSubmit = () => {
      const data = JSON.parse(JSON.stringify(cashbookData.value))
      store.dispatch('cashbook/edit', data)
        .then(response => {
          if (response.status === 200) {
            router.push({ name: 'cashbook-list' })
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      cashbookData,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
